.bookImageWrap{
    width: 360px; height: 500px;
    overflow: hidden;
    /* margin-left: 20px; */
    /* display: flex;
    justify-content: center;
    border: none; */
}

.bookImage{
    width: 100%; height: 100%;
    object-fit: cover;
    border: 1px solid #ddd;
}

.productPic .carousel-control-prev, .productPic .carousel-control-next{
  display: inline;
  display: flex;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #6a6a6a !important;
}

.productOnePic .carousel-control-prev, .productOnePic .carousel-control-next {
  display: none;
}

.productPic .carousel-control-prev {
  margin-left: 8px;
}

.productPic .carousel-control-next {
  margin-right: 8px;
}

.productPic .carousel-control-prev span {
  margin-right: 5px;
}

.productPic .carousel-control-next span {
  margin-left: 5px;
}

.productPic .carousel-control-prev:hover, .productPic .carousel-control-next:hover {
  opacity: 0.6;
}

.productPic .carousel-control-prev:active, .productPic .carousel-control-next:active {
  background-color: #6a6a6a !important;
}

@media screen and (max-width: 992px){
  .bookImageWrap{
    width: 300px; height: 450px;
  }
}
@media screen and (max-width: 768px){
  .bookImageWrap{
    width: 200px; height: 320px;
  }
}
@media screen and (max-width: 576px){
  .bookImageWrap{
    width: 150px; height: 230px;
  }
}