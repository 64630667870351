.toast_wrap{
    width: 300px; height: auto;
    position: fixed; bottom: 80px; right: 30px;
    z-index: 2000 !important;
}

.toast {
    background-color: white;
    opacity: 0.98;
}

.toast_header button:focus{
    box-shadow: none !important;
}

.notify_more_btn {
    margin: 0 5px;
    padding: 3px 5px;
    background-color: rgb(77, 145, 182);
    border: none;
    border-radius: 5px;
    font-size: 13px;
    color: white;
    transition: 0.3s ease;
}

.notify_more_btn:hover {
    background-color: #427D9D;
}

.notification_box{
    max-height: 30vh;
    overflow-y: scroll;
}

.notification_content{
    margin: 10px 10px 0px 10px;
    padding: 5px;
    border-bottom: 1px solid rgb(185, 185, 185);
    cursor: pointer;
    transition: 0.2s ease;
}

.date{
    color: #6A6A6A;
    font-size: 14px;
}

.notification_content:hover{
    background-color: #f5f5f5;
    border-radius: 10px;
}

.notification_content:first-of-type{
    margin-top: 0;
}

.notification_content p:nth-child(2){
    text-align: right;
}

.notification_read, .notification_read .date{
    color:#979797 !important;
}

