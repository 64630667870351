/* 공통 */
.text_hidden{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs button:not(:active){
  color: #427D9D;
}

.result_count{
  margin: 0 10px;
  font-size: 20px;
  color: #6A6A6A;
}

.more_btn{
  width: 70px; height: 90%;
  padding: 7px;
  border-radius: 5px;
  background-color: rgb(77, 145, 182);
  border: none;
}

.more_btn:hover, .more_btn:active, .more_btn:focus{
  background-color: #427D9D !important;
}

/* 상품 */
.product{
  margin-top:10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  word-break: keep-all;
}

@media screen and (max-width: 992px) {
  .product{
    justify-content: center;
  }
}

.product .box_content{
  border-radius: 10px;
  transition: 0.3s;
}

@media screen and(min-width: 576px) {
  .product .box_content{
    max-height: 200px;
  }
}

.product .box_content:hover{
  background-color: #f1f1f1;
}

.product .pic_box{
  height: 100%;
  min-width: 80px;
}

.product .bookpic{
  width: 100%;
  aspect-ratio: 1/1.65;
  border-radius: 5px;
  box-shadow: 0 0 4px #cecece;
  object-fit: cover;
}

@media screen and (min-width: 576px) {
  .product .bookpic{
    max-height: 180px;
  }
}

.product .info_box{
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product .book_info{
  line-height: 2;
  color:#6A6A6A;
}

.product .book_info span::after{
  content:'|';
  margin: 5px;
}

.product .book_info span:last-of-type::after{
  display: none;
}

@media screen and (max-width: 576px) {
  .product .info_box{
    height: auto;
  }

  .product .seller_info{
    margin-top: 20px;
  }
}

.category_badge{
  margin-bottom: 3px;
  font-size: 14px;
  background-color: #4d91b6 !important;
  border: none !important;
}

.product .book_title{
  font-size: 18px;
}

.product .price{
  color : #979797;
  font-size: 14px;
}

.product .seller_info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-clamp: 1;
  overflow: hidden;
}

.view_like_box span{
  color: #6A6A6A;
}

.product .person_icon{
  margin-right: 3px;
  font-size: 20px;
}

/* 게시판 */
.total_box{
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.board .onPostBtn {
  background-color: #4d91b6; border: none;
}

.board .board_box {
  transition: 0.2s ease;
}

.board .board_box:nth-of-type(2n-1){
  border-right: 1px solid #e8e8e8;
}

@media screen and (max-width: 576px) {
  .board .board_box:nth-of-type(2n-1){
    border-right: none;
  }
}

.board .board_box:hover{
  background-color: #f1f1f1;
}

.board .list {
  padding: 25px; border-bottom: 1px solid #e8e8e8; cursor: pointer;
}

.board .listTitle {
  font-size: 18px;
}

.board .board_content{
  font-size: 16px;
  color: #6A6A6A;
}

.board .userid{
  font-size: 14px;
}

.board .userid:after{
  content:'|';
  margin: 5px;
}

.board .date {
  color : #6A6A6A;
  font-size: 14px;
}

/* 사용자 */
.user{
  margin-top:10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .user{
    justify-content: center;
  } 
}

.user .info_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 10px;
  transition: 0.3s;
}

.user .info_box:hover{
  background-color: #f1f1f1;
}

.user .image_box{
  width: 40%;
  min-width: 80px;
  max-width: 100px;
  border-radius: 100%;
}

.user .profile_image {
  width: 100%; height: 100%;
  aspect-ratio: 1/1;
  border-radius: 100%;
  box-shadow: 0px 0px 5px #cecece;
  
}

.user .profile_default {
  width: 100%; height: 100%;
  color: #cacaca;
}

.user .info_list_box{
  margin-left:10px;
  width: 55%;
}

.user .manner_score{
  font-size: 16px;
}

.user .manner_score_star{
  margin-right: 5px;
  font-size: 30px;
  color: #FFC100;
}

.user .user_info{
  font-size: 18px;
  text-align: left;
}

.user .trade_info{
  margin-top:5px;
}

.user .trade_info span{
  text-align: left;
  font-size: 14px;
}

.user .trade_info span:first-of-type::after{
  content: '|';
  margin: 0 5px;
}
