.header{
  border: none;
  padding-top: 20px;
  padding-bottom: 10px;
}

.modal{
  --bs-modal-width: 400px;
}

.box{
    padding: 10px;
    border-radius: 5px;
}

.title .box{
    width: 50%;
    text-align: center;
}

table{
    width: 100%;
}

.checkbox{
  margin: 10px 20px 10px 30px;
}

.input_checkbox {
    transform: scale(2);
}

.reason_row{
    
}

.reason{
    margin:5px; padding: 5px 0;
    text-align: center;
}

.btn_row {
    padding: 30px 20px 20px;
    text-align: center;
}

.report_error {
    color: rgb(220, 53, 69);
}