/* 공통 */
.undrag{
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.text_hidden{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: keep-all;
}

.other_user_nologin{
    width: 100%;
}

.info_box {
    width: 100%;
}

.section_box {
    width:100%;
    margin-top: 40px;
}

.more_btn{
    width: 70px; height: 90%;
    padding: 7px;
    border-radius: 5px;
    color: rgb(77, 145, 182);
    border: 1px solid rgb(77, 145, 182);
}

.more_btn:hover, .more_btn:active{
    background-color: rgb(77, 145, 182) !important;
    border: 1px solid rgb(77, 145, 182) !important;
}

/* 다른 사용자 정보 */
.user_box {
    padding: 10px 0;
    position: relative;
    border-bottom: 1px solid #dddddd;
}

.image_box{
    width: 100%; max-width: 180px; 
    min-width: 100px;
    border-radius: 100%;
}

.profile_default {
    width: 100%; height: 100%;
    color: #cacaca;
}

.profile_image {
    width: 100%; height: 100%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    box-shadow: 0px 0px 5px #cecece;
}

.title {
    margin-bottom : 10px;
    display: flex; justify-content: space-between;
    align-items: center; flex-wrap: wrap;
}

.title .box{
    width: 30%;
    text-align: center;
}

.title_font {
    line-height: 2;
}

.score_box {
    margin-bottom:20px;
    position:relative;
    text-align: center;
}

.manner_score{
    position: absolute; top: 50%; left:50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    z-index: 1;
}

.manner_score_star{
    position: absolute; top: 50%; left:50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    color: #FFC100;
}

.user_info{
    margin-top:10px; padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
}

.sell_count:after{
    content:'|';
    margin: 5px;
}

/* 신고 */
.report_box{
    width: 100px;
    position: absolute; right:5px; top:5px;
}

.alertBtn {
    border: none;
}

.alertBtn:hover, .alertBtn:active, .alertBtn:focus{
    background-color: transparent !important;
    /* border: 1px solid #6C757D; */
    color: #6C757D !important;
}

.already_reported{
    color: darkgreen;
}

.blocked_box{
    width: 100%;
    margin: 15px 0 10px;
    text-align: center;
}

.blocked{
    width: 50%;
    margin: 10px 0; padding: 10px;
    color: rgb(220, 53, 69);
    border: none;
    border-radius: 7px;
    font-size: 18px;
    text-align: center;
}

/* 정렬, 필터 */
.sort_box {
    margin-left: 10px;
    padding: 6px 10px;
    display: flex; 
    align-items: center;
    cursor: pointer;
    background-color: rgb(77, 145, 182);
    border-radius: 7px;
    font-size: 25px;
    color: white;
}

.sortType_name {
    font-size: 17px;
}

@media screen and (max-width: 576px) {
    .sort_box {
        font-size: 1.5rem;
    }

    .sortType_name {
        font-size: 1rem;
    }
    
}

.filter_box {
    padding: 10px;
    width: 280px;
    background-color: rgb(214, 234, 244);
    border-radius: 5px;
    border:none;
    font-size: 15px;
    font-family: 'Pretendard-SemiBold' !important;
}

.filter_box input {
    transform: scale(1.5);
}

.filter_icon{
    margin-left: 8px;
    line-height: 2;   
}

.filter_btn{
    cursor: pointer;
    color: rgb(77, 145, 182);
    font-size: 2.3rem;
    transition: 0.2s ease;
}

@media screen and (max-width: 576px) {
    .filter_btn{
        font-size: 1.5rem;
    }
}

.filter_btn:hover{
    color: #427D9D;
}

.filter_input_box {
    display: flex; justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.filter_label {
    margin: 2px 5px 2px 3px;
}

.filter_apply{
    background-color: rgb(77, 145, 182);
    border:none;
}

.filter_apply:hover, .filter_apply:active, .filter_apply:focus{
    background-color: #427D9D !important;
    border:none;
}

/* 유저 리뷰 */
.reviewtag_title{
    margin-bottom: 16px;
    text-align: center;
}

@media screen and (max-width: 992px) {
    .reviewtag_title{
        margin-top: 30px;
    }
}

.review_table{
    margin: 0 auto;
    width: 80%;
}

.liked{
    padding: 10px 0;
    width: 25%; min-width: 80px;
    text-align: center;
}

.tag{
    padding: 10px 0;
    width: 50%; min-width: 240px;
    text-align: left;
}

.liked .box, .tag .box {
    margin: 5px 0;
}

.tag_name_box{
    padding: 5px 10px;
    background-color: #ebe9e9;
    border-radius: 10px;
    box-shadow: 0 1px 3px #cecece;
}

.arrow_more_btn{
    width: 40px;
    margin: 0 0 8px;
    padding: 7px;
    border-radius: 5px;
}

/* 상품 구매, 판매 리뷰 */
.date{
    color: #6A6A6A;
    font-size: 14px;
}

.review_tag_table {
    width: 100%;
}

.review_box, .review_box_long{
    margin: 3px 0;
    border-bottom: 1px solid #ddd;
}

.review_box *{
    margin: 3px 0;
    text-align: center;
}

.review_box .book_title{
    margin-bottom: 5px;
    padding: 0;
    height: 25px;
}

.review_content {
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.bar{
    margin: 5px 0;
    width: 100%; height: 1px;
    background-color: #c8c8c8;
}

/* 상품 정보 */
.book_list_box{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 25px;
}

.book_box{ 
    width: 100%;
    margin:0 auto;
    min-width: 80px; max-width: 150px;
}

@media screen and (max-width:768px) {
    .book_list_box{
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
    }
}

@media screen and (max-width:576px) {
    .book_list_box{
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
    }
}

.book_card a{
    text-decoration: none; color:black;
}

.book_card a:hover{
    text-decoration: underline;
}

.book_image_box{
    display: flex; justify-content: center;
    align-items: center;
    border: 1px solid #dddddd;
    box-shadow: 0 0 4px #cecece;
    border-radius: 5px;
}

.book_image {
    width: 100%; height: 100%;
    display:block;
    aspect-ratio: 1/1.65;
    border-radius: 5px;
}

.book_info{
    margin-top: 10px;
    padding: 0 10px;
    word-break: keep-all;
}

.book_info .price{
    padding:0; margin: 5px 0 0;
    text-align: center;
    color : #979797;
    font-size: 14px;
}

/* 페이지네이션 */
.pagination_wrap {
    margin-top : 20px;
    display: flex; justify-content: center;
}

.pagination_wrap ul{
    margin:0 !important;
}

.pagination *{
    border:none;
}

.pagination a{
    color : #a6a6a6 !important;
    text-decoration: none !important;
    position: inherit;
}

.pagination a:hover{
    color : #4D91B6 !important;
    text-decoration: underline !important;
}

.pagination{
    --bs-pagination-color: #ddd !important;
    --bs-pagination-bg: transparent !important;
    --bs-pagination-active-color: #4D91B6 !important;
    --bs-pagination-active-border-color: none !important;
    --bs-pagination-active-bg: none !important;
    --bs-pagination-hover-bg: none !important;
    --bs-pagination-focus-color: #4D91B6 !important;
    --bs-pagination-focus-box-shadow: none !important;
}

.back_wrap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.back_btn{
    padding: 7px;
    float: right;
    border-radius: 5px;
}

@media screen and (max-width:768px ) {
    .pagination_wrap {
        margin-top : 10px;
        display: flex; flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .back_wrap{
        justify-content: center;
    }
}
