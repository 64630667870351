/* 공통 */
.text_hidden{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.undrag{
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

/* 상단 박스 */
.top_box{
    width: 100%;
}

.top_inner_box{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* 필터링, 정렬 */
.buttonList{
    display: flex; justify-content: center;
    line-height: 0;
}

.drop_menu a{
    line-height: 1.5;
}

.option_dropdown button{
    width: 100px;
    margin-right: 10px;
    background-color: rgb(77, 145, 182) !important;
    border:1px solid rgb(77, 145, 182) !important;
}

.option_dropdown a:active{
    background-color: rgb(77, 145, 182) !important;
}

@media screen and (max-width:768px) {
    .option_dropdown button{
        margin:0 5px 15px 5px;
    }
}

/* 상품 등록 */
.add_btn{
    height: 100%;
    color:#fff;
    background-color: rgb(77, 145, 182) !important;
    border:1px solid rgb(77, 145, 182) !important;
    white-space: nowrap;
}

.add_btn:hover, .add_btn:active{
    background-color: #427D9D !important;
    border:1px solid #427D9D !important;
}

/* 상품 검색 */
.input_group a:active{
    background-color: rgb(77, 145, 182) !important;
}

.search_option_btn {
    color:#fff;
    background-color: rgb(77, 145, 182) !important;
    border:1px solid rgb(77, 145, 182) !important;
}

.search_option_btn:hover{
    background-color: rgb(77, 145, 182) !important;
    border:1px solid rgb(77, 145, 182) !important;
}

.search_confirm_btn{
    color:#fff;
    background-color: rgb(77, 145, 182) !important;
    border:1px solid rgb(77, 145, 182) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
    border-bottom-right-radius: var(--bs-border-radius) !important;
}

.search_confirm_btn:hover{
    background-color: #427D9D !important;
    border:1px solid #427D9D !important;
}

.searchKeyword:focus{
    border-color:none !important;
    box-shadow: none !important;
}

/* 카테고리 박스 */
.category_box{
    width:100%;
    padding: 0;
}

.category_list {
    margin: 18px 0;
    padding: 5px 8px;
    display: flex; justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 10px;
    border: 1px solid #dddddd;
    text-align: center;
}

.category {
    margin: 5px 0;
}

.category_btn {
    width: 100%;
    padding:5px 10px;
    background-color: transparent;
    border-radius: 5px;
    color : black;
    border: 1px solid rgb(77, 145, 182);
}

.category_btn:hover {
    background-color: rgb(77, 145, 182);
    color:#fff;
    transition: 0.2s;
}

button:disabled.category_btn {
    color:#fff;
    background-color: rgb(77, 145, 182);
}

/* 상품 리스트 */
.productList{
    width: 100%;
    display: flex; flex-direction: column;
    justify-content: center;
}

.product_box{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.product_link:hover{
    text-decoration: none;
}

.product{
    margin: 10px 0;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    background-color: #fdfdfd;
    border: 1px solid #dddddd;
    transition: 0.4s;
}

@media screen and(min-width: 576px) {
    .product{
        max-height: 300px;
    }
}

.product:hover{
    box-shadow: 0 0 5px #cecece;
}

.loading_box{
    margin:50px;
}

.pic_box{
    height: 100%;
    min-width: 80px;
}

.bookpic{
    width: 100%;
    border-radius: 5px;
    aspect-ratio: 1/1.65;
    box-shadow: 0 0 4px #cecece;
    object-fit: cover;
}

@media screen and (min-width: 576px) {
    .bookpic{
        max-height: 180px;
    }
}

.no_bookpic{
    height: 100%;
    border-radius: 5px;
    aspect-ratio: 1/1.65;
    box-shadow: 0 0 4px #cecece;
    opacity: 0.5;
}

.category_badge{
    font-size: 14px;
    background-color: #4d91b6 !important;
    border: none !important;
}

.info_box{
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.book_title{
    line-height: 2;
    font-size: 18px;
}

.book_info{
    line-height: 2;
    color:#6A6A6A;
}

.book_info span::after{
    content:'|';
    margin: 5px;
}

.book_info span:last-of-type::after{
    display: none;
}

.price{
    color : #979797;
    font-size: 14px;
}

.seller_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-clamp: 1;
    overflow: hidden;
}

@media screen and (max-width: 576px) {
    .product .info_box{
        height: 100%;
        margin: 16px 0 0 0 !important;
    }

    .book_title, .person_icon{
        font-size: 16px;
    }

    .book_info{ font-size: 14px; }

    .product .seller_info{
        margin-top: 20px;
    }
}

.view_like_box span{
    color: #6A6A6A;
}

.person_icon{
    margin-right: 3px;
    font-size: 20px;
}

.option_box {
    width: 100%;
    display: flex; 
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 768px) {
    .option_box {
        justify-content: center;
        flex-wrap: wrap;
    }
}

.pagination *{
    border:none;
}

.pagination a{
    color : #a6a6a6 !important;
    text-decoration: none !important;
}

.pagination a:hover{
    color : #4D91B6 !important;
    text-decoration: underline !important;
}

.pagination{
    margin:0 !important;
    padding:0 !important;
    --bs-pagination-color: #ddd !important;
    --bs-pagination-bg : transparent !important;
    --bs-pagination-active-color: #4D91B6 !important;
    --bs-pagination-active-border-color: none !important;
    --bs-pagination-active-bg: transparent !important;
    --bs-pagination-hover-color: transparent !important;
    --bs-pagination-hover-bg: transparent !important;
    --bs-pagination-focus-color: transparent !important;
    --bs-pagination-focus-box-shadow: none !important;
}
