/* COMMON */
.heartContent, .buy_seller, .boardContent {
  display: -webkit-box;
  -webkit-box-orient: vertical; 
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.inner { display: flex; justify-content: space-between; }
.leftNav { 
  width: 15%;
  white-space: nowrap; overflow: hidden;
  background-color: #ffffff;
}
.content { width: 80%; }
.contentHeader { 
  height: 49px;
  padding: 10px 0 5px;
  display: flex; 
  align-items: center; 
  justify-content: space-between; 
  border-bottom: 1px solid #666; 
}
.empty { margin-top: 30px; text-align: center; }
.date { 
  font-size: 14px; 
  color: #6A6A6A!important; 
  white-space: nowrap;
}
.sorting button, .sorting button:hover, .sorting button:active, .sorting button:focus { 
  background-color: #4D91B6 !important; border: none; 
}
/* .sorting button { margin-bottom: 5px; } */
.sortingItem:active, .sortingItem.focus { background-color: #4D91B6; color: #fff; }

.reaction { display: flex; }
.reaction p { padding-right: 10px; color: #6A6A6A }
.reaction p:last-of-type { padding-right: 0; }
.reaction p span { font-size: 14px; }

.tradeWrap, .heartWrap, .boardWrap, .productWrap, .notification_content {
  transition-property: background-color;
  transition-duration: 0.25s; 
}
.tradeWrap:hover, .heartWrap:hover, .boardWrap:hover, .productWrap:hover, .notification_content:hover { 
  background-color: #f1f1f1; 
}


/* LEFT NAV */
.navProfile {
  position: relative; display: flex;
  justify-content: center; align-items: center;
  /* border-bottom: 1px solid #666; */
}
.navProfileImgWrap {
  position: relative;
  width: 100px; height: 100px;
  overflow: hidden;
  border-radius: 100%;
}
.navProfileImg {
  width: 100%; height: 100%;
  object-fit: cover;
}
.profile_default {
  width: 100px; height: 100px;
  color: #cacaca;
}
.navProfileOverlay {
  position: absolute;
  width: 100px; height: 50px;
  margin-bottom: 8px; padding: 5px 0;
  left: 50%; bottom: 0;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  border-radius: 0 0 50px 50px;
}
.navProfileInfo { position: relative; }
.score {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}
.starIcon {
  color: #FFC100;
  margin-right: 2px;
}
.mannerScore { font-size: 14px; }
.nickname { white-space: nowrap; font-size: 14px; }

/* MOBILE-SIZE */
.leftNav_mobile { 
  display: none; 
  justify-content: space-between;
  border-bottom: 2px solid #4D91B6;
  padding-bottom: 5px;
}

/* max-width: 992px */
@media screen and (max-width: 992px) {
  .inner { display: block; }
  .leftNav { display: none; }
  .leftNav_mobile { display: flex; }
  .content { width: 100%; padding: 30px 0; }
  .navProfile { padding: 0; justify-content: flex-end; border: none;  }
  .navProfileImgWrap, .profile_default { width: 50px; height: 50px; }
  .nickname { display: flex; justify-content: end; }
}
.dropdown { display: flex; align-items: end; }
.navItem {
  border: none;
  position: relative;
}
.accordion_header {
  padding: 10px 20px;
  white-space: nowrap;
  cursor: pointer;
}
.accordion_body {
  position: absolute; display: none;
  width: 150px; 
  margin-top: 8px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.accordion_body a { padding-top: 10px; }
.accordion_body a:first-of-type { padding-top: 0; }
.navItem:hover .accordion_header { background-color: #4D91B6; color: #fff; }
.navItem:hover .accordion_body {
  display: flex; flex-direction: column;
}
@media screen and (max-width: 550px) {
  .accordion_header { padding: 10px 15px; }
}



/* PAGINATION */
.pagination { display: flex; justify-content: center; margin: 20px 0; }
.pageBtnWrap { display: flex; align-items: center; }
.pageBtn { 
  font-size: small; margin: 0 5px; 
  background-color: transparent; 
  border: 0; 
}
.pageNumWrap { margin: 0 10px; }
.pageNum { margin: 0 10px; background-color: transparent; border: 0; }
.pageNum:hover, .pageBtn:hover, .pageBtn:active { color: #4D91B6; text-decoration: underline; }


/* TRADE LIST */
.tradeWrap { 
  padding: 10px 0 10px 10px; display: flex; 
  border-bottom: 1px solid #ddd; 
}
.tradeImgWrap { width: 80px; height: 117.44px; }
.tradeImg { width: 100%; height: 100%; object-fit: cover; }
.tradeInfo { 
  padding: 0 10px; 
  display: flex; 
  flex-direction: column; 
}
.tradeInfo .date { margin-top: auto; }
.dealmaker { width: 100px; margin: auto 10px auto auto; }
.tradeNickname { display: flex; align-items: center; margin-right: auto; }

.reviewBtn {  
  padding: 4px 10px;
  background-color: #427D9D; 
  border: none; border-radius: 10px; 
  color: white; text-wrap: nowrap;
  text-align: center;
}
.reviewBtn.complete { 
  width: 75.34px;
  padding: 1.5px 5px;
  background-color: #bbb; 
} 
.reviewBtn.block {
  width: 75.34px;
  padding: 1.5px 5px; 
  background-color: transparent; 
  border: 1px solid #bbb; color: #bbb; 
}

/* REVIEW LIST*/
.reviewList { min-height: 100px; }
.reviewWrap { padding: 10px; vertical-align: middle; border-bottom: 1px solid #ddd }
.star { white-space: nowrap; }
@media screen and (max-width: 768px) {
  .reviewNickname { padding-left: 20px; }
}

.readMore { margin-left: 5px; color: #aaaaaa; cursor: pointer; }
.readMore:hover { text-decoration: underline; }

.toggleBtn, .toggleBtn:hover, .toggleBtn:active, .toggleBtn:focus, 
.toggleBtn:first-child:active, 
.toggleBtn.show ~ .toggleBtn:active { 
  background-color: transparent; 
  border: 0; color: #949494!important;
  box-shadow: none; outline: none;
  background-color: none!important;
  --bs-btn-active-bg: #fff!important;
  --bs-btn-active-border-color: #fff!important;
}
.toggleBtn { 
  width: 35px; 
  padding: 0!important;
  font-size: 20px; 
}
.toggleBtn::after { content: none; }

.toggleMenu {}
.dropdownItem:active, .dropdownItem:focus { background-color: #eee; color: #444 }



/* HEART LIST*/
.heartHeader .pagination { margin: 0; }

.heartList { padding-bottom: 30px; }
.heartWrap { 
  padding: 10px; display: flex; 
  border-bottom: 1px solid #ddd; 
}
.heartImgWrap { width: 80px; height: 117.44px; }
.heartImg { width: 100%; height: 100%; }

.heartInfo { 
  padding: 0 10px; 
  display: flex; 
  flex-direction: column;
  flex-grow: 1; 
}
.heartTitle { font-size: 16px; }
.heartContent { display: flex; }
.heartContent span { color: #6A6A6A; font-size: 13px; }
.heartContent span::after { content: '|'; padding: 0 5px; }
.heartContent span:last-of-type::after { content: ''; }
.heartPrice { font-size: 16px; }

.heartFooter { 
  margin-top: auto; display: flex;
}
.heartSeller { 
  display: flex; 
  align-items: center; 
  margin-bottom: auto;
}


/* PRODUCT LIST */
.productList { 
  display: flex; flex-wrap: wrap; 
  padding-bottom: 20px;
}

.productGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
}

.productInfo {
  padding: 10px;
  display: flex; 
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1; text-align: center;
}
.productImg {
  width: 100%; height: auto;
  margin-bottom: 10px;
}
.productContent { flex-grow: 1; }
.productContent p { text-align: center;}
.productTitle, .productPrice { margin: 5px 0; }

@media screen and (max-width: 768px) {
  .productGrid { grid-template-columns: repeat(3, 1fr); }
}


/* BOARDPOST LIST*/
.boardWrap { 
  display: flex; 
  justify-content: space-between; 
  padding: 15px 10px 10px;
  border-bottom: 1px solid lightgrey; 
}
.boardWrap:hover { background-color: #f1f1f1; }
.boardContent { 
  margin-bottom: 10px;
  font-size: 14px; 
  color: #6A6A6A;
}
.boardImgWrap{
  margin-right: 15px; 
  width: 80px; height: 80px;
  border-radius: 5px;
  overflow: hidden;
}
.boardImg { 
  width: 100%; height: 100%;
  object-fit: cover; 
}
.boardInfo {}
.boardInfo.withImage { width: 90%; }
.boardInfo.noImage { width: 100%; }
.boardBottom { display: flex; justify-content: space-between; }


/* PRODUCTPOST LIST / BOARDPOST LIST */
.postDate { font-size: 14px; color: #6A6A6A; }


/* MYPAGE EDIT */
.confirm { 
  display: flex; 
  flex-direction: column; 
  text-align: center; 
  justify-content: center; 
}
.inputBox { padding-top: 20px; }
.ban { margin: 0 auto 20px; color: #DC3545; }

.editPage { padding: 40px; display: flex; justify-content: center; align-items: center; }
.form_box { width: 500px }
.profileImgWrap {
  margin-right: 30px; 
  width: 120px; height: 120px; 
  border-radius: 50%; 
  background-color: rgb(221, 242, 252); 
  overflow: hidden;
}
.profileIcon { font-size: 70px; color: rgb(22, 72, 99); }
.profileImg { width: 100%; height: 100%; object-fit: cover; }
.fileBtn { cursor: pointer; }
.email { padding: 2px 5px; }
.inputWrap { width: 350px; }
.input_form { padding: 2px 5px; border: 1px solid #ddd; border-radius: 2px; }
.input_box { display: flex; margin-top: 10px; }
.input_box .value { width: 30%; }
.input_box input { width: 70%; }

.error_box { margin-left: 30%; width: 70%; color: #DC3545; font-size: 14px; }

.btnWrap { margin-top: 20px; }
.btnWrap .cancel { margin-left: 10px; }
.btnWrap .edit { background-color: rgb(77, 145, 182); border: 1px solid rgb(77, 145, 182); }
.btnWrap .edit:hover { background: rgb(22, 72, 99); border: 1px solid rgb(22, 72, 99); }

@media screen and (max-width: 992px) {
  .confirm { padding: 100px 0; }
}
@media screen and (max-width: 768px) {
  .infoWrap { flex-direction: column; }
  .profileImgWrap { margin: 30px 0; width: 150px; height: 150px; }
  .profileIcon { font-size: 80px; }
  .inputWrap { width: auto; }
  .btnWrap { margin-top: 40px; }
  .form_box { width: 100%; }
}


/* REVIEW WRITE */
.rating { margin: 80px auto 0; text-align: center; }
.selectTag { padding: 30px 0; }
.selectTag .tagWrap { display: flex; flex-wrap: wrap; gap: 10px 20px; }
.selectTag .tagWrap .tagBtn { 
  display: flex; width: calc(50% - 10px); 
  padding: 5px 0; border: 0; border-radius: 10px; 
}
.selectTag .tagWrap input[type='checkbox'] { margin-left:15px; scale: 1.8; }
.selectTag .tagWrap .tagBtn p { margin-left: 20px; }

.review p { margin: 15px 0; }
.category_badge{
  margin: 0 7px; display: flex; align-items: center;
  font-size: 13px;
  background-color: #4d91b6 !important;
  border: none !important;
}
/* .review  p > span { 
  margin: 0 5px; padding: 3px 8px; 
  background-color: #427D9D; 
  border-radius: 7px; 
  font-size: 12px; color: white; 
} */
.review textarea {
  min-height: 150px;
  border-radius: 5px;
}
.review textarea:focus{
  border: 1px solid #4D91B6;
}

.btnWrap { text-align: end; }
.submitBtn {
  margin: 0 10px;
  background-color: #4D91B6;
  border: 1px solid #4D91B6;
  color: #fff;
}

.submitBtn:hover, .submitBtn:active{
  background-color: #427D9D !important;
  border: 1px solid #427D9D !important;
  color: #fff;
}
/* notifications */
.notification_info{
  width: 100%;
  border-bottom: 1px solid #666;
}

.notification_info td{
  padding-bottom: 10px;
}


.notifications{
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #427D9D;
  color:white;
  border-radius: 10px;
}

.notification_content{
  padding: 11px 10px !important;
  display: flex; justify-content: space-between;
  border-bottom: 1px solid rgb(185, 185, 185);
  cursor: pointer;
}

.notification_read{
  color:#979797 !important;
}