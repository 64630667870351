/* font */
@font-face {
  font-family: 'Pretendard-Bold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-SemiBold';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Medium';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
  font-style: normal;
}
@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-style: normal;
}

body{font-family: 'Pretendard-SemiBold'; height:100%;}
.medium{font-family: 'Pretendard-Medium';}
.regular{font-family: 'Pretendard-Regular';}
.bold{font-family: 'Pretendard-Bold';}

.app-wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper{
  flex: 1;
}

/* Header */
.header{
  position: sticky; top: 0px;
  padding: 4px 0;
  background-color: white; z-index: 1000;
}
.onScroll::after{
  content: ''; display: block; position: absolute;
  bottom: -3px;
  width: 100%; height: 3px;
  background: linear-gradient(to top, transparent, rgba(0,0,0,0.1));
}
.toggleBtn:active, .toggleBtn:focus{
  outline: none!important;
  box-shadow: none!important;
}

.searchBtn {
  background-color: #4D91B6 !important; 
  border: 1px solid #4D91B6 !important;
}

.searchBtn:hover, .searchBtn:active, .searchBtn:focus {
  background-color: #427D9D !important;
  border: 1px solid #427D9D !important;
}

.form-control:focus{
  border-color:#ddd !important;
  box-shadow: none !important;
}

@media screen and (max-width: 767px){
  .searchForm{margin: 20px 0;}
}
@media screen and (min-width: 768px){
  .toggleBtnWrap{display: none!important;}
} /* 헤더 토글 버튼 md보다 큰 화면에서 아예 보이지 않도록 설정 */
.toggleBtnWrap{height: 50px;}


/* footer */
/* .page-footer{background-color: #427D9D;} */
.footerLogoImg{width: 250px;}
.footerWrap{padding: 0px 30px;}
.footerBorder{border-left: 1px solid #c0c0c0; border-right: 1px solid #c0c0c0;}

.page-footer{background-color: #f1f1f1;}
.footerTextSize{font-size: 14px; color: #4d4d4d;}
.footerTitle{font-size: 15px;}

.footerGithubIcon{margin-right: 3px; color: #4d4d4d;}
.footerConWrap{color: #4d4d4d;}
.footercontributors{margin-right: 15px;}

.footerYoutube{
  margin-top: 5px; margin-right: 10px; padding: 5px;
  width: 30px; height: 30px;
  border-radius: 50%; font-size: 16px;
  color: #4d4d4d; background-color: white;
}

@media screen and (max-width: 992px){
  .footerWrap{padding: 0 15px;}
  .footerLogoImg{width: 200px;}
}
@media screen and (max-width: 768px){
  .footerBorder{border: none;}
  .footerConWrap{margin-top: 15px;}
  .footerConWrap:nth-of-type(3){border-left: 1px solid #c0c0c0;}
  .footerWrap{padding: 0 10px;}
}
@media screen and (max-width: 576px){
  .footerWrap{padding-left: 10px;}
}

/* chatBtn */
.react-datepicker__portal {
  z-index: 10; /* 원하는 z-index 값으로 설정 */
}
.chatBtnWrap{position: fixed; bottom: 30px; right: 80px; z-index: 100;}
.chatBtn{
  width: 40px; height: 40px; border-radius: 50%;
  background-color: white; border: none; box-shadow: 0 0 3px #6b6b6b;
  z-index: 50;
}
.ChatDotsFill{color: rgb(77, 145, 182); font-size: 25px;}

/* notificationBtn */
.notify_btn_wrap{
  position: fixed; bottom: 30px; right: 30px; z-index: 100;
}
.notification_btn{
  width: 40px; height: 40px; border-radius: 50%;
  background-color: rgb(77, 145, 182); border: none; box-shadow: 0 0 2px #6b6b6b;
  z-index: 2000;
}
.notification{color: white; font-size: 25px;}

/* common setting */
*{margin: 0; padding: 0; box-sizing: border-box;}
ul, ol, li{list-style: none;}
a{text-decoration: none; color:black;}
.inner{margin:0 auto; max-width: 1000px;}

input{
  padding: 2px 5px; border: 1px solid #ddd; border-radius: 2px;
  outline: none!important; font-family: 'Pretendard-Regular';
}
input[type="file"]{
  /* 파일 업로드 기본 버튼 삭제 */
  width: 0; height: 0; padding: 0; margin: 0; border: 0;
  position: relative; z-index: 10;
}

textarea{
  width: 100%; font-family: 'Pretendard-Regular';
  border: 1px solid #ddd; border-radius: 2px;
  resize: none;
  overflow-y: hidden; /* prevents scroll bar flash */
  padding: 1.1em; /* prevents text jump on Enter keypress */
  padding-bottom: 0.2em;
  line-height: 1.6;
}
textarea:focus{outline: none!important;}

input::placeholder{color: #979797; font-family: 'Pretendard-Regular';}
textarea::placeholder{color: #979797; font-family: 'Pretendard-Regular';}
textarea:focus{}

/* 빈 데이터 박스 또는 로딩 박스 */
.blank_box{
  margin: 40px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blank_message{
  padding: 7px 15px;
  background-color: rgb(77, 145, 182);
  color: white;
  border-radius: 50px;
}

/* EmptyError */
.emptyErrorMsg{
  position: relative; z-index: 10;
  margin-left: 5px; padding: 0px 6px; height: 24px;
  background-color: rgb(220, 53, 69); color: white;
  border-radius: 5px; font-size: 14px; flex-shrink: 0;
}
@keyframes vibration {
  from {
    transform: rotate(1deg);
  }
  to {
    transform: rotate(-1deg);
  }
}
.emptyErrorMsg.vibration{animation: vibration .1s infinite;}
.emptyIcon{margin-right: 3px;}

/* footer */
footer{
  margin-top: 50px;
}