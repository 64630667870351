/* 공통 */
.undrag{
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

/* 로그인 */
.login_box{
    margin-top:15vh;
}

.login_inner_box{
    width: 500px;
    padding: 30px 0;
    display: flex; flex-direction: column;
    align-items: center;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
}

.form_box {
    width: 80%;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
}

.input_box {
    width: 50%; max-width: 258px;
    margin: 10px;
}

.input_form {
    width: 100%;
    margin : 10px 0;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.login_btn {
    width: 60px; 
    padding: 6px 10px;
    margin: 0 10px;
    background-color: rgb(77, 145, 182);
    border: 1px solid rgb(77, 145, 182);
    border-radius: 7px;
    color:#fff;
    font-size: 14px;
    transition: 0.2s ease;
}

.login_btn:hover, .login_btn:focus, .login_btn:active{
    background-color: #427D9D !important;
    border: 1px solid #427D9D !important;
}

.password_box{
    position: relative;
}

.password_eye{
    padding: none;
    position: absolute; right:3%; top:10%;
    border: none;
    background: transparent;
    cursor:pointer;
}

.more_info {
    margin-top: 30px;
}

.join_info {
    margin-right: 10px;
    color: #868686;
}

.join_link{
    color: rgb(22, 72, 99);
}

.join_link:hover{
    text-decoration: underline;
}

.find_info {
    margin: 0 5px;
    position: relative;
}

.error_box {
    margin: 5px 0;
    color : rgb(220, 53, 69);
}

.back_btn {
    width: 60px; padding: 6px 10px;
    margin: 0 10px;
    font-size: 14px;
    transition: 0.2s ease;
}

/* 비번 찾기 */
.box{
    width: 50%;
    margin-top:15vh; padding: 30px 0;
    display: flex; flex-direction: column;
    align-items: center;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
}

.email_input{
    width: 80%;
    margin : 20px 0;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(204, 204, 204);
}

/* 비번 초기화 */ 
.reset_box {
    width: 80%;
    margin-top: 15vh; padding: 30px 0;
    display: flex; flex-direction: column;
    align-items: center;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
}