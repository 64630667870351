.sold{

}

.box{

}

.section_sub_box{

}

.salesList {
    padding: 30px 0;
}

.title{
    margin-bottom : 10px;
    display: flex; justify-content: space-between;
    align-items: center;
}

.title_font{
    line-height: 2;
}

.moreBtn{
    background-color: #4D91B6;
    color: #fff;
    border: none;
}

.moreBtn:hover {
    background-color: #427D9D;
}

.bookCard {
    padding: 5px;
    text-decoration: none; color:black;
}

.bookImageBox{
    width: 100%;
    display: flex; 
    justify-content: center;
    margin-bottom: 15px;
}

.bookImage{
    width: 100%; 
    aspect-ratio: 1/1.25;
}

.noBookImage{
    width: 100%; 
    aspect-ratio: 1/1.25;
    /* display:block; */

}

.productName {
    text-align: center;
}


.book_info{
    margin-top: 10px;
    padding: 0 20px;
    word-break: keep-all;
}

.book_info .book_tilte{
    margin-bottom: 5px;
    padding: 0;
    height: 25px;
}

.text_hidden{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.book_info .price{
    padding:0; margin: 5px 0 0;
    text-align: center;
    color : #979797;
    font-size: 14px;
}