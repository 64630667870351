.col{margin: 0;}
.box{width: 49%; height: 623px;}
.chatListBox{overflow-y: auto; background-color: #f1f1f1; border-radius: 2px;}
.chatting{height: 388px; overflow-y: auto;}

/* chatList(chatUser) */
.active{background-color: #f1f1f1!important;}
.profileWrap{
  position: relative;
  width: 75px; height: 55px;
}
.profileImgWrap{
  position: absolute; top: 0; left: 0;
  width: 50px; height: 50px;
  border-radius: 25px; background-color: rgb(221, 242, 252);
  overflow: hidden;
}
.profileShadow{
  position: absolute; top: 0; left: 0;
  width: 50px; height: 50px;
  border-radius: 25px; box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}
.profileProductImgWrap{
  position: absolute; top: 13px; left: 30px;
  width: 100%; height: 100%; object-fit: cover;
  width: 35px; height: 45px; background-color: white;
  border-radius: 3px; border: 3px solid white;
  overflow: hidden;
}
.profileProductShadow{
  position: absolute; top: 16px; left: 33px; z-index: 10;
  width: 29px; height: 39px;
  border-radius: 2px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}
.profileIcon{font-size: 40px;}
.profileImg{
  width: 100%; height: 100%; object-fit: cover;
  border-radius: 2px;
}
.nickname{padding-right: 30px;}
.chatUser{
  width: 100%; padding: 15px;
  background-color: white;
  border-bottom: 1px solid #ddd;
  cursor: pointer;}
.chatUser:last-of-type{border-bottom: none;}

.chattingList{border: 1px solid #ddd; border-radius: 2px;}
.unreadCount{margin-left: 5px; padding: 0 7px; border-radius: 30px; background-color: red; color: white; align-self: flex-end; font-size: 13px;}
.latestMsg{
  color: #949494;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.chatInfo{width: 100%;}
.chatDate{
  margin: 0px; color: #a3a3a3; font-size: 13px; flex-shrink: 0;
}
.userName{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}


/* empty chatList */
.empty{width: 100%; height: 400px; background-color: #a5a5a5; color: white; border-radius: 2px;}
.tear{font-size: 100px; margin-bottom: 20px;}

.emptyChat{width: 100%; height: 623px; background-color: #a5a5a5; color: white; border-radius: 2px;}
.send{font-size: 40px; margin-bottom: 20px;}


/* chatting */
.msgProfileWrap{padding: 15px;}
.msgProfileImgWrap{
  margin-right: 10px; width: 55.9px; height: 55.9px;
  border-radius: 50%; background-color: rgb(221, 242, 252); overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}
.msgProfileImgWrap .profileIcon{font-size: 40px;}
.msgProfileImgWrap .profileImg{width: 100%; height: 100%; object-fit: cover;}

.toggleBtn, .toggleBtn:hover, .toggleBtn:active, .toggleBtn:focus, 
.toggleBtn:first-child:active, 
.toggleBtn.show ~ .toggleBtn:active
{ 
  background-color: transparent; 
  border: 0; color: #949494!important;
  box-shadow: none; outline: none;
}
.toggleBtn:active{background-color: none!important;}
.toggleBtn{
  --bs-btn-active-bg: #fff!important;
  --bs-btn-active-border-color: #fff!important;
}
.toggleBtn::after{ content: none; }
.toggleBtn{font-size: 20px; width: 35px;}
.toggleBtn:hover{background-color: #f1f1f1;}

.dropdownItem:active, .dropdownItem:focus { background-color: #eee; color: #444 }
.chatOut{font-size: 18px; margin-right: 7px;}
.reportComplete{margin-right: 7px; margin-left: 2px;}

.chattingRoom{border-radius: 2px; border: 1px solid #ddd;}
.profileWrap{padding: 15px;}
.productWrap{padding: 15px; border-top: 1px solid #ddd;}
.productImgWrap{
  width: 45px; height: 55px; margin-right: 8px; overflow: hidden;
  background-color: white; border-radius: 3px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.banned{margin-right: 3px; color: rgb(220, 53, 69);}

.deletedWrap{background-color: #f1f1f1;}
.deletedText{width: 100%; height: 55px; color: rgb(220, 53, 69);}
.deletedIcon{margin: 0px 5px; font-size: 50px;}

.report{color: #575757; font-size: 14px;}
.reported{cursor: default; padding: 4px 16px; color: darkgreen;}
.reported:hover{cursor: default;}

.soldOut{text-decoration: line-through; color: #949494; filter: grayscale(100); line-height: 1rem;}
.soldOutText{font-size: 12px; color: #949494;}

.productContent{overflow: hidden;}
.productName{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.price{font-size: 14px; color: #a3a3a3;}
.btnWrap{margin-left: 8px; flex-shrink: 0;}
.btn{width: 75px; border: 1px solid rgb(77, 145, 182); color: rgb(77, 145, 182);}
.btn:hover{background-color: rgb(77, 145, 182); border: 1px solid rgb(77, 145, 182)}
.btn:active{background-color: rgb(77, 145, 182)!important; border: 1px solid rgb(77, 145, 182)!important;}

.reviewed{
  padding: 1.5px 5px;
  border: none; background-color: #BBBBBB; color: white;
  border-radius: 10px;
}

.chatting{padding: 15px 20px 0; border-top: 1px solid #ddd;}

/* chatMsg */
.chatBox span{padding: 7px 10px; background-color: rgb(211, 240, 254); border-radius: 5px;}
.chatWrap{margin-bottom: 20px;}
.date{margin: 0px 5px; color: #a3a3a3; font-size: 12px;}
.ron{color: rgb(22, 72, 99);}
.chatDifDate{
  padding: 3px 10px; margin: 10px 0;
  color: white; font-size: 13px;
  background-color: #c9c9c9; border-radius: 20px;
}

.chatMsg{white-space: pre-wrap; word-break: break-all;}

/* chatMsg mine */
.chatWrapMine{padding: 10px 0;}
.chatBoxMine span{padding: 7px 10px; border: 1px solid #ddd; border-radius: 5px;}


/* chatForm */
.chatForm{margin: 10px; background-color: rgb(211, 240, 254); border-radius: 10px;}
.chatText{padding: 7px 10px; height: 40px; border: none; background-color: transparent;}
.chatText:focus{outline: none;}
.chatBtn{padding: 10px; cursor: pointer; color: white;}
.chatBanBtn{cursor: default;}