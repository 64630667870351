.error_box {
    margin: 80px;
}

.title {
    margin-bottom: 20px;
    font-size: 40px;
    text-align: center;
    color: #4D91B6;
}

.content_box {
    text-align: center;
    line-height: 2;
}

.message_highlight {
    text-decoration: underline;
    color: rgb(220, 53, 69);
}

.error_alert{
    font-size: 17px;
    color: #427D9D;
}


.verify_goHome {
    border: none;
    border-radius: 20px;
    padding: 6px 15px;
    margin-top: 20px;
    background-color: #4D91B6;
    transition-property: background-color;
    transition-duration: 0.25s; 
    font-size: 15px;
}

.verify_goHome:hover {
    background-color: #427D9D;
}

.goHome {
    color: #fff;
}

