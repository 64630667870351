
.onPost {
  background-color: #4D91B6; 
  border: 1px solid #4D91B6;
}
.onPost:hover {
  background-color: #427D9D;
  border: 1px solid #427D9D;
}

.boardForm .col{margin: 20px 0;}
.boardForm .titleInput, .contentInput {
  width: 90%; 
  padding: 5px 10px;
  border-radius: 5px;
}
.boardForm .contentInput {height: 240px; min-height: 240px;}

.titleBox{display: flex; margin-bottom: 5px; align-items: center;}
.contentBox{margin-bottom: 5px;}
.titleBox, .contentBox, .btnWrap{padding: 0 10px;}
.titleBox label{text-align: center;}
.contentBox label{padding-top: 5px; text-align: center;}
.boardError{padding: 0 10px;}

.boardForm .onPost{margin-right: 10px;}
.count{color: #979797; font-size: 13px; font-family: 'Pretendard-Regular';}

.imgBox{
  display: flex;
  width: 100%;
  justify-content: start; align-items: center;
}
.imageUploadBtn{
  display: flex; position: relative;
  height: 180px;
  justify-content: center; align-items: center;
  border-radius: 5px;
}
.fileBtn{
  width: 100%; height: 100%; position: absolute; z-index: 2;
  background-color: rgba(0,0,0,0.1); border-radius: 5px; border: 1px solid #979797; cursor: pointer;
}
.uploadedImgBox{
  display: flex; align-items: center; justify-content: center;
  overflow: hidden; position: relative;
  border: 1px solid #979797; border-radius: 5px;
}
.previewImg{
  display: block; width: auto; height: 180px;
  object-fit: cover; object-position: center;}
.previewImgDelBtn{
  position: absolute; top: 7px; right: 7px; margin: 0; padding: 0; font-size: 20px; color: #979797; line-height: 1;
  z-index: 10; border: none; background-color: transparent;
}
/* 사진 업로드 삭제 버튼 빈 공간 없애기 위한 코드 */
.previewImgDelBtn > svg{vertical-align: text-top;}
.previewDefaultImg{position: absolute; font-size: 100px; color: #979797;}


.container{padding: 0}
.sec{padding: 80px 0;}
.row{margin: 0; padding: 0;}