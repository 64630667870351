h3{margin: 0; display: flex; align-items: center;}
h4, p{margin: 0;}

.sec{padding: 60px 0 30px;}
.btn{font-size: 14px; padding: 6px 10px;}

/* carousel - 슬라이드 */
.carousel{
  min-height: 260px;
}

.carousel-control-prev, .carousel-control-next {
  display: none;
}

.carousel-indicators{
  margin-bottom: 0;
  transform: translate(0, 30px);
}

.carousel-indicators [data-bs-target]{
  height: 5px !important;
  border-radius: 5px !important;
  background-clip: border-box !important;
  border-top: none !important;
  border-bottom: none !important;
}

[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: rgb(77, 145, 182) !important;
}

/* MiniBook.js */
.productTop{margin-bottom: 20px;}
.productWrap{text-align: center; padding-bottom: 15px;}
.productImgWrap{
  max-width: 130px; height: 200px; margin-bottom: 10px; overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 4px #cecece;
}
.productImg{
  width: auto; height: 100%; object-fit: cover;
}
@media screen and (max-width: 1200px){
  .productImgWrap{max-width: 110px; height: 150px;}
}
@media screen and (max-width: 992px){
  .productImgWrap{max-width: 160px; height: 200px;}
}
@media screen and (max-width: 768px){
  .productImgWrap{max-width: 130px; height: 150px;}
}
@media screen and (max-width: 576px){
  .productImgWrap{max-width: 150px; height: 200px;}
  .inner{padding: 0px 20px;}
}
.productTitle{
  font-size: 16px;
}
.productPrice{font-size: 14px; color: #979797;}

.textWrap .productTitle{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  flex-shrink: 0;
}

/* BoardList.js */

.boardHeadWrap{
  border-top: 2px solid #ddd; border-bottom: 2px solid #ddd!important;
  font-size: 15px; color: #6A6A6A;
}

.boardWrap{
  padding: 15px 5px;
  border-bottom: 1px solid #ddd;
}

.boardTopWrap{margin-bottom: 3px;}
.boardTitle{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  flex-shrink: 0;
}

.boardInsideContent{
  margin-left: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #979797; font-size: 14.5px;
}
/* .boardInsideContent::before{content: '|'; margin: 0px 5px;} */

.boardNickname{
  padding-left: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #4f4f4f;
}

.boardCnt, .boardDate{font-size: 14px ; color: #979797;}
.boardDate{width: 100%; margin-right: 15px;}
.boardEye{width: 60px;}
.boardHeart{width: 40px;}
.boardChatLeftDots{width: 40px;}

@media screen and (max-width: 767px){
  .boardNickname{padding-left: 0px;}
  .boardContentWrap{padding: 10px 0px 0px;}
  .boardDate{margin-right: 10px;}
  .boardDate::before{content: '|'; margin: 0 10px;}
}