@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-style: normal;
}

.title{padding-bottom: 10px; margin-bottom: 10px; border-bottom: 1.5px solid #ddd;}
.inputTitle{margin-bottom: 5px;}

.boardForm .col{margin: 20px 0; padding: 0 10px;}
.boardForm{padding: 5px 10px;}
.input{width: 100%; padding: 5px 10px; border-radius: 5px;}
.container{padding: 0}

.inputBox{width: 100%;}
.content{min-height: 200px;}
.titleTextarea{height: 36px;}
.count{color: #979797; font-size: 13px; font-family: 'Pretendard-Regular';}

.boardForm .reset{margin-left: 10px;}

.essentialInput::after{content: "*"; margin-left: 2px; color: #427D9D;}
.category{margin-right: 20px;}
.radioWrap{padding: 5px 10px;}

.selectedDay{background-color: #427D9D; border-radius: 50%!important; border: none;}

.onPost {
  background-color: #4D91B6; 
  border: 1px solid #4D91B6;
}
.onPost:hover {
  background-color: #427D9D;
  border: 1px solid #427D9D;
}

/* image upload */
.imgComment{color: #979797; margin: 0;}
.imgBox{
  display: flex; padding: 0 10px;
  width: 100%;
  justify-content: start; align-items: center;
}
.imgTop{margin: 5px 0 10px!important;}
.imageUploadBtn{
  display: flex; position: relative;
  height: 170px;
  justify-content: center; align-items: center;
  border-radius: 5px;
}
.fileBtn{
  width: 100%; height: 100%; position: absolute; z-index: 2;
  background-color: rgba(0,0,0,0.1); border-radius: 5px; border: 1px solid #979797; cursor: pointer;
}
.uploadedImgBox{
  display: flex; align-items: center; justify-content: center;
  height: 170px;
  overflow: hidden; position: relative;
  border: 1px solid #979797; border-radius: 5px;
}
.previewImg{
  display: block; width: auto; height: 170px;
  object-fit: cover; object-position: center;}
.previewImgDelBtn{
  position: absolute; top: 7px; right: 7px; margin: 0; padding: 0; font-size: 20px; color: #979797; line-height: 1;
  z-index: 10; border: none; background-color: transparent;
}
/* 사진 업로드 삭제 버튼 빈 공간 없애기 위한 코드 */
.previewImgDelBtn > svg{vertical-align: text-top;}
.previewDefaultImg{position: absolute; font-size: 100px; color: #979797;}

