/* common */
.updateBtn, .deleteBtn {
  border: 1px solid #ccc;
  border: none;
}
.updateBtn:hover, .deleteBtn:hover {
  background-color: transparent;
  color: #6C757D;
}
.updateBtn:active, .deleteBtn:active {
  background-color: transparent !important;
}
.noUpdateBtn {display: none;} 

.goBoard {
  background-color: #4D91B6;
  border: none;
  color: #fff;
}
.reportBtn, .replyDeleteBtn {
  border: none;
  background-color: transparent;
}
.reportBtn:hover, .replyDeleteBtn:hover{
  background-color: transparent;
  color: #6C757D;
}
.reportBtn:active, .replyDeleteBtn:active{
  background-color: transparent !important;
}

.onPost {
  background-color: #4D91B6; 
  border: none;
}
.onPost:hover, .goBoard:hover{
  background-color: #427D9D;
}
.onPost:active, .onPost:focus, .goBoard:active, .goBoard:focus{
  background-color: #427D9D !important;
}
.listInfo, .detailInfo, .replyInfo {
  font-size: 14px; color: #6A6A6A;
}


/* board (게시판) */
.board .onPostBtn {background-color: #4D91B6; border: none;}
.board .boardHeader {margin-top: 40px}
.board .listHeader {padding: 20px 0 17px; border-bottom: 1px solid #171717;}

.board .list {padding: 32px; border-bottom: 1px solid #e8e8e8; cursor: pointer;}
.board .listTitle, .listContent  {margin-bottom: 13px;}
.board .listTitle {font-size: 18px;}
.board .listContent {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #6A6A6A;
}

.bannedUserId {color: rgb(220, 53, 69);}

.boardSort button{
  width: 100px; height: 100%;
  margin-right: 10px; padding: 5px 10px;
  background-color: rgb(77, 145, 182) !important;
  border:1px solid rgb(77, 145, 182) !important;
}

.boardSort a:active{
  background-color: rgb(77, 145, 182) !important;
}

.board .listInfo .userid:after, .bannedUserId:after{
  content:'|';
  margin: 5px;
  color: #6A6A6A;
}

@media screen and (max-width: 992px) {
  .board .listHeader {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .listHeader {
    display: flex;
    flex-direction: column;
  }
  .searchOrderWrap {
    margin-top:10px;
    display: flex;
    justify-content: end;
    align-items: center;
  }
}


/* board-Detail (상세 게시글) */
.boardDetail .title {margin: 40px 0 20px}
.boardDetail .blockedPost {
  margin: 60px 0;
}
.boardDetail .detailWrapper {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 60px 50px;
  margin-bottom: 50px;
}
.detailMain .heartBtn{cursor: pointer; margin: 0 10px 10px 0}
.detailMain .detailTitle { width: 70%; word-break: break-all;}
.detailMain .deatilContent {white-space: pre-wrap; word-break: break-all;}
.detailMain .detailInfo{
  padding: 0 0 20px;
  margin: 0 0 20px;
  border-bottom: 1px solid #e8e8e8
}

/* filter: grayscale(100); */

.detailMain .myBlockedMessage {font-size: 19px; color : rgb(223, 0, 0);}
.detailMain .myblockTitle {color: #8a8a8a;}

.detailMain .detailInfo .userid {color: #4D91B6;}
.detailMain .detailInfo .userid:after{
  content:'|';
  margin: 5px;
  color: #6A6A6A;
}
.detailMain .detailContentWrap {
  padding: 0 0 170px;
}
.detailContentWrap .detailImage {margin-bottom: 15px;}
.detailContentWrap .detailImage .boardImg {
  margin-bottom: 10px; display: block;
  width: 100%; height: auto;
}
.detailMain .btnWrap .updateBtn {margin-right: 5px;}

@media screen and (min-width: 992px){
  .detailContentWrap .detailImage .boardImg{width: 60%;}
}
@media screen and (min-width: 1200px){
  .detailContentWrap .detailImage .boardImg{width: 40%;}
}

@media screen and (max-width: 768px) {
  .btnWrap {
    display: flex;
    flex-direction: column;
  }
  .detailMain .btnWrap .updateBtn {margin: 0px;}
}




/* ReplyForm (댓글 폼) */
.replyHeader {border-bottom: 1px solid #e8e8e8; padding: 0 0 7px 0;}
.replyCountWrap .replyCount {margin-right: 15px;}
.replyCountWrap .chatIcon, .replyLength, .likeCounts {color: #427D9D;}
.replyCountWrap .chatIcon, .heartIcon {
  margin-right: 5px;
}
.replyCountWrap .heartIcon {color: #427D9D; cursor: pointer;}

.replyList {padding: 20px 0; border-bottom: 1px solid #eee;}
.replyList .replyInfo .userId {color: #427D9D;}
.replyList .replyInfo .userId:after, .writerId:after{
  content:'|';
  margin: 5px;
}

.replyForm {padding: 20px 0;}
.replyForm .replyId {margin-bottom: 10px; color: #427D9D; font-size: 15px;}
.replyForm .replyInput{
  padding: 0 16px;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  width: 100%;
}
.replyForm .replySubmit{
  background-color: #4D91B6; 
  border: none;
  margin: 0 0 0 10px;
}
.replySubmit:hover, .replySubmit:active, .replySubmit:focus {background-color: #427D9D !important;}

.loginNeeded {margin: 100px 0 40px 0; text-align: center;}
.login {color: #427D9D;}


/* Pagination */
.pageNav {margin: 30px 0 80px;}
.prevBtn, .nextBtn, .firstBtn, .lastBtn {
  background-color: transparent; 
  border: none; 
  color: #494949;
  cursor: pointer;
}

.pageNav .currentPageBtn {background-color: transparent; color: #4D91B6;}
.pageNav .noCurrentPageBtn {background-color: transparent;}
.currentPageBtn, .noCurrentPageBtn{
  margin: 0 7px;
  border: none; 
  color: #494949;
}

.firstBtn, .lastBtn {padding: 6px 5px;}

.prevBtn:hover, .nextBtn:hover, .currentPageBtn:hover, .noCurrentPageBtn:hover, .firstBtn:hover, .lastBtn:hover {
  background-color: transparent; color: #4D91B6; text-decoration: underline;
}

.pageNav .prevBtn:active, .pageNav .nextBtn:active, .pageNav .currentPageBtn:active, .pageNav .noCurrentPageBtn:active, .pageNav .firstBtn:active, .pageNav .lastBtn:active { background-color: transparent; }


/* BoardSearchInput */
/* 상품 검색 */
.input_group a:active{
  background-color: rgb(77, 145, 182) !important;
}

.search_option_btn {
  color:#fff;
  background-color: rgb(77, 145, 182) !important;
  border:1px solid rgb(77, 145, 182) !important;
}

.search_option_btn:hover{
  background-color: rgb(77, 145, 182) !important;
  border:1px solid rgb(77, 145, 182) !important;
}

.search_confirm_btn{
  color:#fff;
  background-color: rgb(77, 145, 182) !important;
  border:1px solid rgb(77, 145, 182) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.search_confirm_btn:hover{
  background-color: #427D9D !important;
  border:1px solid #427D9D !important;
}

.searchKeyword:focus{
  border-color: #ddd !important;
  box-shadow: none !important;
}



