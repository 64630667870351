.modal{--bs-modal-width: 400px;}

/* .modalBtn{font-size: 17px;} */

.noBtn{border: 1px solid #ccc;}

.yesBtn{
  width: 50px;
  background-color: #4D91B6; border: 1px solid #4D91B6;
}
.yesBtn:hover{background-color: #427D9D; border: 1px solid #427D9D;}
.yesBtn:active{background-color: #427D9D !important; border: 1px solid #427D9D !important;}