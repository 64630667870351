/* common */
.updateBtn, .deleteBtn {
    border: 1px solid #ccc;
    border: none;
}
.updateBtn:hover, .deleteBtn:hover {
    background-color: transparent;
    color: #6C757D;
}
.updateBtn:active, .deleteBtn:active {
    background-color: transparent !important;
}
.reportBtn{
    color: #6a6a6a;
    font-size: 16px;
    border: none;
    background-color: transparent;
}
.reportBtn:hover{
    background-color: transparent;
    color: #6a6a6a;
}
.reportBtn:active{
    background-color: transparent !important;
}

.chatBtn {
    border: none;
    padding: 0;
    font-size: 16px;
    color: #6a6a6a;
}
.chatBtn:hover {
    background-color: transparent;
    color: #6a6a6a;
}
.chatBtn:active {
    background-color: transparent !important;
}

.soldOutBadge{
    background-color: #4d91b6 ;
    border-radius: 8px;
    font-size: 20px;
    color: #fff;
    border: none;
    padding: 5px 10px;
    margin-left: 15px;
}

.blockedBadge {
    display: block; margin-bottom: 5px;
    font-size: 20px;
    color: rgb(220, 53, 69);
}

/* Blocked Post */
.blockedPost{margin: 100px 0;}

/* Product Caution */
.productCaution{
    padding: 0 10px;
    color: #6a6a6a;
}

.cautionTitle {
    font-size: 15px;
}
.cautionContent {
    font-size: 14px;
}


/* Product Header */
.productHeader {
    border-bottom: 1px solid #e8e8e8;
    padding: 30px 20px 10px ;
}
.productTitle {margin-bottom: 10px;}
.soldProductName {
    color: #949494;
    text-decoration: line-through;
    filter: grayscale(100);
}

.productHeaderInfo {
    display: flex;
    justify-content: space-between;
}
.productHeaderInfo .info {
    font-size: 14px;
    color: #6a6a6a;
    
}
.productHeaderInfo .info:nth-of-type(1):after, .infoFirst .info::after {
  content:'|';
  margin: 8px;
  color: #ccc;
}

.headerBtnWrap{flex-shrink: 0;}



/* Product Detail */
.productDetail {
    padding: 30px 20px;
    border-bottom: 1px solid #e8e8e8;
}

/* .productCarousel{
    width: 100%; height: 100%;
} */

.productImgWrap{
    width: 360px; height: 500px;
}

.scoreBox{
    position: absolute; top: 40px; left: 25px;
    text-align: center;
}
.mannerScore{
    position: absolute; top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    font-size: 13px;
    z-index: 1;
}
.profileImgWrap{
  width: 50px; height: 50px;
  border-radius: 25px;
  background-color: rgb(221, 242, 252);
  overflow: hidden; box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.profileIcon{
  font-size: 40px;
}
.profileImg{
  width: 100%; height: 100%; object-fit: cover;
}
.starIcon{
    position: absolute; top: 0; left: 0;
    transform: translate(-50%, -50%);
    /* font-size: 60px; */
    color: #FFC100;
}

.sellerInfo{
  position: relative;
}
.blockedUser{
  color: rgb(220, 53, 69);
}
.banIcon{
  margin-right: 5px;
}
.productGroup {
    position: relative;
    margin: 30px 0;
}
.detailTitle {
    width: 35%;
}

.sellerNickname{
    margin: 0 15px;
    font-size: 25px;
}

.emptyData{
  color: #979797;
}

.category_badge{
  font-size: 15px;
  background-color: #4d91b6 !important;
  border: none !important;
}

.productPrice {
    font-size: 20px;
}

.bookDescription {
    width: 60%;
    white-space: pre-wrap; 
    word-break: break-all;
}

@media screen and (max-width: 992px){
    .productImgWrap{
        width: 300px; height: 450px;
    }
}

@media screen and (max-width: 768px) {
.detailTitle {
    width: 100px;
}
  .productHeaderInfo{
    flex-direction: column;
    justify-content: start;
    align-items: start!important;
  }
  .headerInfoText{
    flex-direction: column;
  }
  .productHeaderInfo .infoFirst {
      margin-bottom: 5px;
  }
  .productHeaderInfo .info:nth-of-type(2)::after {
    content: '';
  }
  .productDetail .bookImage {
    width: 80%;
  }
  
  .productImgWrap{
    width: 200px; height: 320px;
}
  
  .profileImgWrap{
    width: 40px; height: 40px;
  }
  .profileIcon{
    font-size: 30px;
  }
  .scoreBox{
    top: 30px; left: 20px;
  }
  .starIcon{
    font-size: 20px!important;
  }
}
@media screen and (max-width: 576px){
    .productImgWrap{
        width: 150px; height: 230px;
    }
}

/* Book Image Carousel */
.productPic{
    margin: 0 10px;
    width: 100%;
    margin-top: 20px;
}


.bookImage{
    object-fit: cover;
    height: 500px;
    border: 1px solid #ddd;
}

.no_book_image{
    object-fit: cover;
    width: 100%;
    border: 1px solid #ddd;
}

.optioninfo{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.optionlist{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 60%;
    font-size: 13px;
    margin-bottom: 20px;
    margin-top: 20px;
    min-width: 450px;
}

.favorite{
    margin-left: 20%;
    align-self: center;
    min-width: 100px;
}

.warning{
    align-self: center;
    min-width: 100px;
}


.name{
    display: flex;
}



.infocate{
    margin-top: 30px;
    margin-left: 20px;
    width: 50%;
    border-radius: 5px;
    height: 80%;
    line-height: 200%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: 400px;
}

.productInfo{
    width: 50%;
}



/* .cateinfo1{
    display: flex;
    flex-direction : row;    
}

.cateinfo2{
    
}

.cateinfo2_1{
    width: 25%;
    color: #a7a2a2;
    font-size: 14px;
}

.cateinfo3{
    width: 75%;
}

.cateinfo3_name{
    width: 75%;
    display: flex;
} */



.user_chat{
    margin-left: 10px;

}

.chat_btn{
    cursor: pointer;
}


.favoriteWrap{
    padding: 6px 10px 6px 0;
}

.heartCount{
    margin-right: 6px;
}

.heartIcon{
    cursor: pointer;
    color: rgb(220, 53, 69);
}

.boxinfo{
    height: 70%;
    align-content: space-around;
}

.info01{
    text-align: center;
    width: 25%;
    line-height : 100px;
    border-radius: 5px;
}

.info02{
    text-align: center;
    width: 70%;
    line-height : 100px;
    border-bottom: 1px solid #ddd;
}

.info022{
    text-align: center;
    width: 70%;
    line-height: 40px;
    align-content: space-around;
    border-bottom: 1px solid #ddd;
}

.infocaution2{
    width: 70%;
    line-height: 30px;
    border-bottom: 1px solid #ddd;
}

.info04{
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.info0102{
    display: flex;
    height: 100px;
    margin-bottom: 1%;
    border-radius: 5px;
    justify-content: space-between;
}

.infocaution{
    display: flex;
    height: 50%;
    margin-bottom: 1%;
    border-radius: 5px;
    justify-content: space-between;
}

.info0405{
    width: 70%;
    display: flex;
    justify-content : center;
    align-items : center;
    text-indent: 10px;
    border-bottom: 1px solid #ddd;
}

.anotherinfo{
    background-color: lightblue;
    height: 300px;
    width: 70%;
    float: right;
    border: 1px solid black;
    border-radius: 5px;   
}

.anotherbookcon{
    height: 300px;
    width: 100%;
}

.anotherbook{
    display: flex;
    text-align: center;
    justify-content: space-around;
    margin: 0 auto;
    padding: 30px;
    line-height: 250%;
}

.another{
    margin-top: 40px;
}

.other_div { 
    margin-top: 300px; 
    border-top: solid 1px #ababab;
    display: grid;
    grid-template-columns: 25% 40% 25%;
}

.Like { margin : 50px 0px 0px 48%; }
.Like img { width: 60px; cursor: pointer; }
.Like h5 { margin-top: 10px;}

