.container_box{
    margin: 70px auto;
}

.title {
    margin-top: 10px;
}

.form_box {
    margin: 30px auto;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
}

.input_box:first-of-type{
    position: relative;
}

.input_box {
    width: 100%; max-width: 500px;
    margin: 10px;
}

.input_form{
    margin : 10px 0;
    width: 80%;
    border: none;
    outline: none;
    border-bottom: 1px solid rgb(204, 204, 204);
}

.input_box.password{
    position: relative;
}

.password_eye{
    padding: none;
    position: absolute; right:10%; top:10%;
    border: none;
    background: transparent;
    font-size: 18px;
    cursor:pointer;
}

.duple_btn {
    width: 80px;
    position:absolute;
    transform : translate(-100%, -20%);
    background-color: transparent !important;
    border: 1px solid rgb(77, 145, 182) !important;
    color:rgb(77, 145, 182) !important;
}

.duple_btn:hover, .duple_btn:focus, .duple_btn:active {
    background-color: transparent !important;
    color:rgb(77, 145, 182) !important;
}


.confirm_btn {
    width: 60px; padding: 6px 10px;
    margin: 0 10px;
    background-color: rgb(77, 145, 182);
    border: 1px solid rgb(77, 145, 182);
    border-radius: 7px;
    color:#fff;
    font-size: 14px;
    transition: 0.2s ease;
}

.confirm_btn:hover{
    background-color: rgb(22, 72, 99);
}

.back_btn {
    width: 60px; padding: 6px 10px;
    margin: 0 10px;
    font-size: 14px;
    transition: 0.2s ease;
}

.back_btn:hover{
    background-color: #6d6d6d;
}

.error_box{
    color :  rgb(220, 53, 69);
}

.error_box.duple_ok {
    color :  rgb(57, 168, 35);
}